<template>
  <div>
    <cabecalho-pagina :titulo="$t('modulos.instrumento.titulos.certificados')" :total-registros="tabela.quantidadeItens"
      sem-botao-ok sem-filtro />


    <tabela-padrao-prime-vue ref="tabela" v-model="tabela.selecionados" class="mt-2" :colunas="tabela.colunas"
      :dados="tabela.dados" :mostrar-acoes="true" filtros-com-colunas :quantidade-itens="tabela.quantidadeItens"
      :por-pagina="tabela.porPagina" :quantidade-paginas="tabela.quantidadePaginas" :pagina="tabela.paginaAtual"
      :mostrar-seletor="false" sem-paginacao @paginar="listarRegistros" @filtrarGlobal="listarRegistros"
      @filtroGlobal="(v) => (filtroAplicado.filter = v)">
      <template v-slot:acoes="{ slotProps }">
        <div class="d-flex">
          <dropdown-padrao text color="secondary">
            <template #botao>
              <v-icon> $dotsVertical </v-icon>
            </template>
            <div>
              <dropdown-padrao-item
                text
                color="secondary"
                @click="abrirVisualizar(slotProps.data)"
              >
                {{
                  gerarLabelVisualizarCalibracao(slotProps.data.tipo)
                }}
              </dropdown-padrao-item>
              
              <dropdown-padrao-item
                v-if="slotProps.data.tipo != 'Terceiro'"
                text
                color="secondary"
                @click="abrirCertificadoOuGerarPDF(slotProps.data)"
              >
                {{ $t('geral.botoes.visualizar') }}
                {{
                  slotProps.data.tipo === 'VerificacaoIntermediaria'
                  ? 'PDF'
                  : $t('modulos.instrumento.certificado')
                }}
              </dropdown-padrao-item>
            </div>
          </dropdown-padrao>
        </div>
      </template>
      <template v-slot:situacaoCertificado="{ slotProps }">
        <div :class="`${formatarSituacaoCertificado(slotProps.data.situacaoCertificado)?.cor
          }--text`">
          {{
            formatarSituacaoCertificado(slotProps.data.situacaoCertificado)
              ?.text
          }}
        </div>
      </template>

      <template v-slot:situacaoAvaliacao="{ slotProps }">
        <div :class="`${formatarSituacaoAvaliacao(slotProps.data.situacaoAvaliacao)?.cor
          }--text`">
          {{
            formatarSituacaoAvaliacao(slotProps.data.situacaoAvaliacao)?.text
          }}
        </div>
      </template>
    </tabela-padrao-prime-vue>
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <botao-padrao outlined color="secondary" class="mr-2" @click="voltar">
        <v-icon>$mdiArrowLeft</v-icon>
        {{ $t('geral.botoes.voltar') }}
      </botao-padrao>
    </div>
    <modal-visualizacao-relatorio ref="modal-pdf" />
  </div>
</template>

<script>
import {
  EnumeradorService,
  InstrumentoService,
} from '@common/services/cadastros/';

import helpers from '@common/utils/helpers';
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import { ModalVisualizacaoRelatorio } from '@components/misc';

export default {
  components: {
    CabecalhoPagina,
    ModalVisualizacaoRelatorio,
  },
  props: ['id'],
  data() {
    return {
      configuracaoRotaVisualizar: {
        Calibracao: 'calibracao-visualizar',
        Terceiro: 'certificado-terceiro-visualizar',
        Interna: 'calibracao-interna-visualizar',
        VerificacaoIntermediaria: 'verificacao-intermediaria-visualizar',
      },
      configuracaoRotaCertificadoPDF: {
        Calibracao: 'calibracao-certificado',
      },
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'codigo',
            text: this.$t('modulos.instrumento.tabela_certificado.codigo'),
            sortable: true,
          },
          {
            value: 'tipo',
            text: this.$t('modulos.instrumento.tabela_certificado.tipo'),
            sortable: true,
            formatter: (v) => {
              if (v)
                return this.opcoes.EnumCalibracaoNivel.find(
                  (el) => el.value == v
                ).text;
            },
          },
          {
            value: 'data',
            text: this.$t('modulos.instrumento.tabela_certificado.data'),
            sortable: true,
            formatter: (v) => {
              if (v) return helpers.formatarDataBr(v, false);
            },
          },
          {
            value: 'ordemServico.nome',
            text: this.$t(
              'modulos.instrumento.tabela_certificado.ordem_servico'
            ),
            sortable: true,
          },
          {
            value: 'laboratorio.nome',
            text: this.$t('modulos.instrumento.tabela_certificado.laboratorio'),
            sortable: true,
          },
          {
            value: 'tipoCalibracao.nome',
            text: this.$t(
              'modulos.instrumento.tabela_certificado.tipo_calibracao'
            ),
            sortable: true,
          },
          {
            value: 'situacaoCertificado',
            text: this.$t('modulos.instrumento.tabela_certificado.situacao'),
            sortable: true,
          },
          {
            value: 'situacaoAvaliacao',
            text: this.$t(
              'modulos.instrumento.tabela_certificado.situacao_avaliacao'
            ),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
        quantidadePaginas: 0,
        paginaAtual: 1,
        porPagina: 10,
      },
      filtroAplicado: { filter: '' },
      opcoes: {
        EnumCalibracaoNivel: [],
      },
    };
  },
  created() {
    const filtros = this.buscarStateFiltros();
    this.filtroAplicado = filtros?.filtroAplicado ?? { filter: '' };
    this.tabela.paginaAtual = filtros?.paginaAtual ?? 1;
    this.tabela.porPagina = filtros?.porPagina ?? 10;
  },
  async mounted() {
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.instrumento.titulos.certificados')
    );
    await this.buscarEnumCalibracaoNivel();
    this.listarRegistros();
  },
  methods: {
    buscarEnumCalibracaoNivel: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await EnumeradorService.buscar('EnumCalibracaoNivel')
        .then((res) => {
          this.opcoes.EnumCalibracaoNivel = res;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    listarRegistros: function (
      paginaAtual = this.tabela.paginaAtual,
      porPagina = this.tabela.porPagina
    ) {
      this.salvarStateFiltros({
        filtroAplicado: this.filtroAplicado,
        paginaAtual,
        porPagina,
        colunasSelecionadas: this.colunasSelecionadas,
      });
      let parametros = {
        page: paginaAtual,
        amountPerPage: porPagina,
        ...this.filtroAplicado,
      };
      this.$store.dispatch('Layout/iniciarCarregamento');
      InstrumentoService.buscarCertificados(this.id, parametros)
        .then((res) => {
          this.tabela.selecionados = [];
          this.tabela.dados = res.data;
          this.tabela.quantidadeItens = res.data.length;
          this.tabela.quantidadePaginas = res.data.totalPages;
          this.tabela.paginaAtual = paginaAtual;
          this.tabela.porPagina = porPagina;

          this.expandTabela();
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    buscarStateFiltros() {
      return this.$store.getters['Filtros/filtros'][this.$route.name];
    },
    salvarStateFiltros(dados) {
      this.$store.dispatch('Filtros/adicionarFiltro', {
        rota: this.$route.name,
        dados,
      });
    },
    filtroGlobal(v) {
      this.filtroAplicado.filter = v;
    },
    filtrarGlobal() {
      this.listarRegistros();
    },
    filtrar(filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },
    formatarSituacaoAvaliacao: function (v) {
      return helpers.SituacaoAvaliacaoEnum.find((el) => el.value == v);
    },
    formatarSituacaoCertificado: function (v) {
      return helpers.SituacaoCertificadoEnum.find((el) => el.value == v);
    },
    voltar: function () {
      this.$router.push({ name: this.$route.query?.retorno || 'instrumento' });
    },
    gerarPDF: function (item) {
      this.$refs['modal-pdf'].abrirModal('verificacao-intermediaria', [
        { simbolo: 'id', valor: item.id },
      ]);
    },
    abrirVisualizar: function (item) {
    
      if (item.tipo == 'Calibracao') {
        this.$router.push({
          name: this.configuracaoRotaVisualizar[item.tipo],
          params: {
            ordemServicoId: item.ordemServico.id,
            instrumentoId: this.id,
            calibracaoId: item.id,
          },
          query: {
            retorno: 'instrumento',
            instrumentoRetorno: this.id,
          },
        });
        return;
      }
      this.$router.push({
        name: this.configuracaoRotaVisualizar[item.tipo],
        params: { id: item.id },
        query: {
          retorno: 'instrumento',
          instrumentoRetorno: this.id,
        },
      });
    },
    abrirCertificadoOuGerarPDF: function (item) {
      
      if (item.tipo == 'VerificacaoIntermediaria') {
        this.gerarPDF(item);
      } else {
        this.$router.push({
          name: this.configuracaoRotaCertificadoPDF[item.tipo],
          params: { id: item.id },
          query: {
            retorno: 'instrumento',
            instrumentoRetorno: this.id,
        },
        });
      }
    },
    gerarLabelVisualizarCalibracao: function (tipo) {
      switch (tipo) {
        case 'Terceiro':
          return this.$t('geral.botoes.visualizar_calibracao_terceiro');
        case 'Interna':
          return this.$t('geral.botoes.visualizar_calibracao_interna');
        case 'VerificacaoIntermediaria':
          return this.$t('geral.botoes.visualizar_intermediaria');
        default:
          return this.$t('geral.botoes.visualizar_calibracao');
      }
    }
  },
};
</script>

<style scoped>
h1 {
  color: blue;
}
</style>
